import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ImageWrapper = styled.div`
  overflow: hidden;

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const SectionContainer = styled.div`
  padding: 80px 24px;

  ${({ theme }) => theme.media.lg} {
    padding: 150px 24px;
  }

  ${({ theme }) => theme.media.xl} {
    padding: 150px 0;
  }
`;

export const SectionContainer2 = styled.div`
  padding: 80px 20px;

  ${({ theme }) => theme.media.md} {
    padding: 80px 24px;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 150px 24px;
  }

  ${({ theme }) => theme.media.xl} {
    padding: 150px 0;
  }
`;

export const SectionBlock = styled.div`
  ${({ theme }) => theme.media.xl} {
    width: 1680px;
    margin: 0 auto;
  }
`;

export const Badge = styled.div<IsActive>`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--Background, #fff);
  border: 1px solid var(--Secondary-2, #c0c3cd);
  cursor: pointer;

  color: var(--Secondary-2, #c0c3cd);
  ${({ theme }) => theme.typography.bodyMo2};

  ${({ theme }) => theme.media.lg} {
    ${({ theme }) => theme.typography.badge};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border: none;
      background: var(--primary, #0f327e);
      color: var(--On-Primary, #fff);
    `}
`;

export const BannerBlock = styled.div`
  color: #fff;
  ${({ theme }) => theme.typography.headMo1};
  white-space: pre-wrap;

  ${({ theme }) => theme.media.md} {
    white-space: initial;
  }

  ${({ theme }) => theme.media.lg} {
    ${({ theme }) => theme.typography.head2};
  }

  ${({ theme }) => theme.media.xl} {
    width: 1680px;
    margin: 0 auto;
    display: block;
    ${({ theme }) => theme.typography.head1};
  }
`;

export const PageListContainer = styled.div`
  min-height: 100px;
  padding: 24px;
  border-bottom: 1px solid #c0c3cd;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageList = styled.div`
  display: flex;
  gap: 8px;

  ${({ theme }) => theme.media.md} {
    gap: 24px;
  }
`;
